@Import "./globalVariables.css";

.img {    
    height: 24px;
    margin: 15px;
    margin-right: 45px;
}

.arrow {
    margin: 10px;
    margin-top: auto;
    margin-bottom: auto;
    height: 20.5px;
}

.link {
    text-decoration: none;
}

.button {
    display: flex;
    justify-content: space-between;
    border: var(--border);
    border-radius: var(--borderRadiusLarge);
    width: 100%;
    background-color: white;
    margin-top: 15px;
}