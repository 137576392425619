@Import "./globalVariables.css";

.trueBtn {
  width: 50%;
  border-radius: 0 0 var(--borderRadiusLarge) 0;
  background-color: transparent;
  height: 100%;
  box-shadow: none;
  padding: 5px;
  border: none;
}

.trueBtnActive {
  width: 50%;
  border-radius: 0 0 var(--borderRadiusLarge) 0;
  background-color: var(--green);
  height: 100%;
  box-shadow: none;
  padding: 5px;
  border: none;
}

.falseBtn {
  width: 50%;
  border-radius: 0 0 0 var(--borderRadiusLarge);
  background-color: transparent;
  box-shadow: none;
  padding: 5px;
  border: none;
}

.falseBtnActive {
  width: 50%;
  background-color: var(--orange);
  box-shadow: none;
  padding: 5px;
  border: none;
}
