.item {
    text-decoration: none;
    display: block;
    width: 25%;
    padding: 5px;
    padding-top: 10px;
  }

.img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 24px;
    padding-bottom: 5px;
}

.imgActive {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 27px;
  padding-bottom: 1px;
}