@import './globalVariables.css';

.img {    
    height: 45px;
    padding: 10px;
    margin: 10px;
    background: var(--lightGrey);
    border-radius: var(--borderRadiusLarge);
}

.arrow {
    margin: 4%;
    margin-top: auto;
    margin-bottom: auto;
    height: 20.5px;
}

.link {
    text-decoration: none;
}